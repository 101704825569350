






import Vue from 'vue'
import Component from 'vue-class-component'

const YoutubeLatestProps = Vue.extend({
    props: {
        channelName: String
    }
})

@Component
export default class YoutubeLatest extends YoutubeLatestProps {

}
