






import { Component, Vue } from 'vue-property-decorator';
import TradingView from '@/components/TradingView.vue';

@Component({
  components: {
    TradingView
  }
})
export default class Markets extends Vue {
  symbols = [
    'TVC:SILVER',
    'TVC:GOLD',
    'COINBASE:BTCUSD'
  ]
}
