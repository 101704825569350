















import { Component, Vue } from 'vue-property-decorator';

declare global {
    interface Window {
        TradingView: any;
    }
}

const TradingViewProps = Vue.extend({
    props: {
        symbol: String
    }
})

@Component
export default class Markets extends TradingViewProps {
  id = Math.random() // where did ._uid go?
  
  async mounted () {
    // console.log('ID', this.id)
    const tradingViewScript = document.createElement('script')
    tradingViewScript.setAttribute('src', 'https://s3.tradingview.com/tv.js')
    document.head.appendChild(tradingViewScript)
    
    // race condition on load...
    new window.TradingView.widget(
        {
            "width": 980,
            "height": 610,
            "symbol": this.symbol,
            "interval": "D",
            "timezone": "Etc/UTC",
            "theme": "light",
            "style": "1",
            "locale": "en",
            "toolbar_bg": "#f1f3f6",
            "enable_publishing": false,
            "allow_symbol_change": true,
            "container_id": this.id
        }
    );
  }
}
