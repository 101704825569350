











import Vue from 'vue'
import Component from 'vue-class-component'
import axios from 'axios'

interface YoutubeItem {
    link: string;
    guid: string;
    title: string;
    pubDate: string;
    thumbNail: string;
}

const YoutubeRecentProps = Vue.extend({
    props: {
        channelId: String
    }
})

@Component
export default class YoutubeRecent extends YoutubeRecentProps {
    items: Array<YoutubeItem> = []
    videoNumber = 0

    async mounted () {
        const reqUrl = await axios.get("https://api.rss2json.com/v1/api.json", {
            params: {
                rss_url: `https://www.youtube.com/feeds/videos.xml?channel_id=${this.channelId}`
            }
        })
        console.log('REQURL', reqUrl)
        this.items = reqUrl.data.items
        
    }

    setVideo(index: number) {
        this.videoNumber = index
    }

    get videoId() {
        if (this.items.length === 0) { return '' }
        const link = this.items[this.videoNumber].link
        const videoId = link.substr(link.indexOf('=') + 1)
        return videoId
    }

    get src() {
        return this.videoId ? `https://youtube.com/embed/${this.videoId}?controls=1` : ''
    }

}
