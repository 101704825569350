
















import { Component, Vue } from 'vue-property-decorator';
import YoutubeLatest from '@/components/YoutubeLatest.vue';
import YoutubeRecent from '@/components/YoutubeRecent.vue';

interface YoutubeChannel {
  id: string;
  name: string;
  tags: Array<string>;
}

@Component({
  components: {
    YoutubeLatest,
    YoutubeRecent
  }
})
export default class Youtube extends Vue {
  filter = ''

  channelNames: Array<string> = [
    'academyofideas',
    'JordanPetersonVideos',
    'UnHerd',
    'Triggerpod',
    'TimcastIRL'
  ]
  channels: Array<YoutubeChannel> = [
    {
      id: 'UCzGiDDKdphJ0GFvEd82WfYQ',
      name: 'VivaFrei',
      tags: [ 'commentary' ]
    },
    {
      id: 'UCukn08nZbecBKnrc2Cw2ebw',
      name: 'IntellectualWave',
      tags: [ 'philosophy' ]
    },
    {
      id: 'UCMxiv15iK_MFayY_3fU9loQ',
      name: 'UnHerd',
      tags: [ 'commentary' ]
    },
    {
      id: 'UCLwNTXWEjVd2qIHLcXxQWxA',
      name: 'TimcastIRL',
      tags: [ 'current events' ]
    },
    {
      id: 'UCL_f53ZEJxp8TtlOkHwMV9Q',
      name: 'JordanPetersonVideos',
      tags: [ 'philosophy', 'commentary' ]
    },
    {
      id: 'UCiRiQGCHGjDLT9FQXFW0I3A',
      name: 'academyofideas',
      tags: [ 'philosophy' ]
    },
    {
      id: 'UCBH5VZE_Y4F3CMcPIzPEB5A',
      name: 'RealVisionFinance',
      tags: [ 'finance' ]
    },
    {
      id: 'UC9ijza42jVR3T6b8bColgvg',
      name: 'KitcoNews',
      tags: [ 'finance' ]
    },
    {
      id: 'UCSHZKyawb77ixDdsGog4iWA',
      name: 'lexfridman',
      tags: [ 'philosophy', 'technology' ]
    },
    {
      id: 'UC5tj5QCpJKIl-KIa4Gib5Xw',
      name: 'MichaelMaliceofficial',
      tags: [ 'philosophy', 'commentary' ]
    },
    {
      id: 'UC5SQnm72FspDNUXK4cB2_xg',
      name: 'phetasy',
      tags: [ 'comedy', 'commentary' ]
    },
    {
      id: 'UCwUizOU8pPWXdXNniXypQEQ',
      name: 'AwakenWithJP',
      tags: [ 'comedy' ]
    },
    {
      id: 'UCD2-QVBQi48RRQTD4Jhxu8w',
      name: 'PeakProsperity',
      tags: [ 'science' ]
    },
    {
      id: 'UCom1i7_NVeSUNyJyuR_NbMQ',
      name: 'itmtrading',
      tags: [ 'finance' ]
    },
    {
      id: 'UCEfe80CP2cs1eLRNQazffZw',
      name: 'DSmithcomic',
      tags: [ 'commentary' ]
    },
    {
      id: 'UCVgL_VeHteGecp5nn0NRztQ',
      name: 'TheStansberryResearch',
      tags: [ 'finance' ]
    },
    {
      id: 'UCXIJgqnII2ZOINSWNOGFThA',
      name: 'FoxNewsChannel',
      tags: [ 'news' ]
    },
    {
      id: 'UCNjyEXSvYUUCzagFAKmaJ1Q',
      name: 'RebelCapitalist',
      tags: [ 'finance' ]
    },
    {
      id: 'UCJquYOG5EL82sKTfH9aMA9Q',
      name: 'RickBeato',
      tags: [ 'music' ]
    },
    {
      id: 'UCoj6RxIAQq8kmJme-5dnN0Q',
      name: 'WillProwse',
      tags: [ 'solar', 'diy' ]
    },
    {
      id: 'UC9K5PLkj0N_b9JTPdSRwPkg',
      name: 'newdiscourses', // james lindsay
      tags: [ 'philosophy' ]
    },
    {
      id: 'UC43_LTf5Z4lbRjKCq0sIAVg',
      name: 'RobertBreedlove22',
      tags: [ 'crypto' ]
    },
    {
      id: 'UC7oPkqeHTwuOZ5CZ-R9f-6w',
      name: 'Triggerpod',
      tags: [ 'commentary' ]
    },
    {
      id: 'UCzrWKkFIRS0kjZf7x24GdGg',
      name: 'WhatBitcoinDidPodcast',
      tags: [ 'crypto' ]
    },
    {
      id: 'UCbvtzZkMmTeM4oWvH3ziGTg',
      name: 'TradersReality',
      tags: [ 'trading', 'crypto' ]
    },
    {
      id: 'UC7AQQ3I02_5D6uShK96MeiA',
      name: 'UC7AQQ3I02_5D6uShK96MeiA',
      tags: [ 'trading', 'crypto' ]
    },
    {
      id: 'UC3sarW4IBiJZR36laQUFgYg',
      name: 'UC3sarW4IBiJZR36laQUFgYg', // Marinade Finance
      tags: [ 'crypto' ]
    },
    {
      id: 'UCru3nlhzHrbgK21x0MdB_eg',
      name: 'nataliebrunellpodcasts',
      tags: [ 'crypto' ]
    }
  ]

  setFilter(filter: string) {
    this.filter = filter
  }

  get tags() {
    const tags = this.channels.map(x => x.tags).reduce((a,b) => a.concat(b))
    return [...new Set(tags)].sort()
  }

  get filteredChannels() {
    if (this.filter === '') { return this.channels }
    return this.channels.filter(channel => {
      return channel.tags.indexOf(this.filter) !== -1
    })
  }
}
